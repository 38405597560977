// TableRow.js

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TableRow extends Component {
  render() {
    return (
        <tr>
          <td>
            {this.props.viatico.id}
          </td>
          <td>
            {this.props.viatico.fecha}
          </td>
          <td>
            {this.props.viatico.dias}
          </td>
          <td>
            {this.props.viatico.fecha_salida}
          </td>
          <td>
            {this.props.viatico.fecha_regreso}
          </td>
          <td>
            {this.props.viatico.tipo !== null ? this.props.viatico.tipo.value : '' }
          </td>
          <td>
            {this.props.viatico.acepta_jefe ? <span className="badge badge-success">Si</span> : <span className="badge badge-danger">No</span>}
          </td>
          <td>
            {this.props.viatico.acepta_rrhh ? <span className="badge badge-success">Si</span> : <span className="badge badge-danger">No</span>}
          </td>
          <td>
            <div className="row">
              <div className="col-md-6 col-12">
                <Link to={"/recursoshumanos/vacaciones/ver/"+this.props.viatico.id} id={this.props.viatico.id} className="btn btn-success">Ver</Link>
              </div>
              {
                !this.props.viatico.acepta_jefe && !this.props.viatico.acepta_rrhh &&
              <div className="col-md-6 col-12">
                <Link to={"/recursoshumanos/vacaciones/actualizar/"+this.props.viatico.id} id={this.props.viatico.id} className="btn btn-primary">Editar</Link>
              </div>
              }
            </div>
          </td>
          {/* <td>
            <button className="btn btn-danger">Delete</button>
          </td> */}
        </tr>
    );
  }
}

export default TableRow;
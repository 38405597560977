import React from 'react'
import Sidebar from '../../layout/Sidebar'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import Alert from '../../components/alerts/Alert'
import { Link } from 'react-router-dom'
import './styles.css'
function RecursosHumanos(){
    return (
        <div id="wrapper">
            <Sidebar/>
            <div className="d-flex flex-column" id="content-wrapper">
                <div id="content">
                    <Navbar />
                    <div className="container-fluid">
                        <Alert />
                        <div className="d-sm-flex justify-content-between align-items-center mb-4">
                            <h3 className="text-dark mb-0">Gestión de Personal</h3>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <Link to="/recursoshumanos/vacaciones"  className="btn btn-success">Acción de Personal</Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="/recursoshumanos/cartaingresos"  className="btn btn-success">Solicitudes de Cartas</Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="/recursoshumanos/certificadoigss"  className="btn btn-success">Certificado de Igss</Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="/recursoshumanos/solicitudirtra"  className="btn btn-success">Solicitud de Irtra</Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="/recursoshumanos/beneficios"  className="btn btn-success">Beneficios</Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="/recursoshumanos/encuestas"  className="btn btn-success">Encuestas</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
            </a>
        </div>
    );
}

export {RecursosHumanos};
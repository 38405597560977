import React, { Component } from 'react'
import { vacacionesActions } from '../../../actions';
import { connect } from 'react-redux'
import {apiUrl} from '../../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import MySelect from '../../../components/select/MySelect';
import { vacacionesService, dropdownsService } from '../../../services';

const LoginSchema = Yup.object().shape({
    fecha_salida: Yup.date()
        .required("Campo requerido"),
    fecha_regreso: Yup.date()
        .required("Campo requerido"),
    fecha_fin: Yup.date()
        .required("Campo requerido"),
    archivo_firmado: Yup
        .mixed()
        .required("Archivo Requerido"),
    acepta_empleado: Yup.boolean()
        .required("Debe Aceptar que la información proporcionada es la correcta")
        .oneOf([true], 'Debe Aceptar que la información proporcionada es la correcta'),
    tipo_id: Yup.string()
        .required("Campo requerido"),
    lugar: Yup.string().when('saldra_pais',{
        is: 1,
        then: Yup.string().required('Requerido'),
        otherwise: Yup.string().nullable(),
    }).nullable(),
});


const emptyOption = { value: "", label: "" };
class VacacionesForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            vacaciones: {
                comentarios: "",
                fecha_salida: "", 
                fecha_regreso: "", 
                fecha_fin:  "",
                dias: 0,
                otros:"", 
                movimiento_id: "",
                tipo_id: "",
                tipo_licencia_id: "",
                saldra_pais: 0,
                lugar: "",
                acepta_empleado: 0,
                archivo_firmado: "",
                archivo_extra: "",
            },
            tipos: [],
            prevTipo: "",
            motivos: [],
            prevMotivo: "",
            licencias: [],
            prevLicencia: "",
            isVacaciones : true,
            isIgss: false,
            isLicencia: true,
            archivo_igss: {},
            archivo_vacaciones: {},
            
        };

        const { dispatch } = this.props;
        
        dropdownsService.getWithChilds('Tipo Acción de Personal').then(
            response => {
                if(response.status){
                    this.setState({tipos: response.data})
                }
            }
        );
        dropdownsService.getWithChilds('Tipos de licencia con goce de salario').then(
            response => {
                if(response.status){
                    this.setState({licencias: response.data})
                }
            }
        );
        vacacionesService.getArchivo('vacaciones').then(
            response => {
                this.setState({archivo_vacaciones: response.data});
            }
        );
        vacacionesService.getArchivo('igss').then(
            response => {
                this.setState({archivo_igss: response.data});
            }
        );
        // dispatch(vacacionesActions.getMotivos());
        // dispatch(vacacionesActions.getTipos());
        // dispatch(vacacionesActions.getLicencias());
        dispatch(vacacionesActions.getDias());

    }

    componentDidMount(){  
        
        if(this.props.id){

            let vacaciones_temp = this.state.vacaciones;

            vacacionesService.getOne(this.props.id).then(
                response => {
                    if(response.status){

                        if(response.data.tipo_id !== undefined){
                            this.setState({prevTipo:response.data.tipo_id});
                            var tipo = this.state.tipos.filter(function(e){ return e.value === response.data.tipo_id;});
                            if(tipo[0] !== undefined){
                                this.setState({motivos: tipo[0].childs});
                                response.data.tipo_id = tipo[0];
                                if(tipo[0].label === 'Vacaciones'){
                                    this.setState({isVacaciones: true});
                                    this.setState({isLicencia: true});
                                }else if(tipo[0].label === 'Licencia con goce de salario'){
                                    this.setState({isVacaciones: false});
                                    this.setState({isLicencia: false});
                                }else{
                                    this.setState({isVacaciones: false});
                                    this.setState({isLicencia: true});
                                }

                                if (tipo[0].label === 'Suspensión IGSS') {
                                    this.setState({isIgss: true});
                                }else{
                                    this.setState({isIgss: false});
                                }
                            }
                        }
                        if(response.data.movimiento_id !== undefined){
                            this.setState({prevMotivo:response.data.movimiento_id});
                            var motivo = this.state.motivos.filter(function(e){ return e.value === response.data.movimiento_id;});
                            if(motivo[0] !== undefined){
                                this.setState({motivos: motivo[0].childs});
                                response.data.movimiento_id = motivo[0];
                            }
                        }
                        if(response.data.tipo_licencia_id !== undefined){
                            this.setState({prevLicencia:response.data.tipo_licencia_id});
                            var licencia = this.state.licencias.filter(function(e){ return e.value === response.data.tipo_licencia_id;});
                            if(licencia[0] !== undefined){
                                this.setState({licencias: licencia[0].childs});
                                response.data.tipo_licencia_id = licencia[0];
                            }
                        }

                        vacaciones_temp = response.data;
                        console.log(vacaciones_temp);
                    }else{
                    }
                },
            ).finally(() => {

                this.setState({vacaciones: vacaciones_temp, loaded: true});
            });
            // let user = JSON.parse(localStorage.getItem('user'));
            // const requestOptions = {
            //     method: 'GET',
            //     headers: { 'Content-Type': 'application/json'},
            // };
            // var url = apiUrl + `api/recursoshumanos/v1/vacaciones/obtener/${this.props.id}?`;
            // var params = {token: user.access_token};
            // let query = Object.keys(params)
            //         .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            //         .join('&');

            // return fetch(url+query, requestOptions).then(response => {
            //     return response.text().then(text => {
            //         const data = text && JSON.parse(text);
            //         if (!response.ok) {
            //             if (response.status === 401) {
            //                 // auto logout if 401 response returned from api
            //                 return response.message;
            //             }
                
            //             const error = (data && data.message) || response.statusText;
            //             return Promise.reject(error);
            //         }

            //         return data;
            //     });
            // })
            // .then(response => {
            //     // this.setState({archivo_igss: response.archivo_igss, archivo_vacaciones: response.archivo_vacaciones});
            //     if(response.vacaciones.tipo_id !== undefined){
            //         for (const result of this.props.tipos) {
            //             if(response.vacaciones.tipo_id === result.value){
            //                 response.vacaciones.tipo_id = result;
            //             }
            //         }
            //     }
            //     this.setState({vacaciones: response.vacaciones});
            // });
        }
    }
    render(){
        const user = JSON.parse(localStorage.getItem('user'));
        return (
            <Formik
                initialValues={this.state.vacaciones}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    console.log(values);
                    values.acepta_empleado = values.acepta_empleado ? 1 : 0;
                    if(values.movimiento_id !== null ){
                        values.movimiento_id = values.movimiento_id.value;
                    }
                    if(values.tipo_id !== null){
                        values.tipo_id = values.tipo_id.value;
                    }
                    if(values.tipo_licencia_id !== null){
                        values.tipo_licencia_id = values.tipo_licencia_id.value;
                    }
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(vacacionesActions.guardar(values));
                    }else{
                        dispatch(vacacionesActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <label htmlFor="nombre">Nombre</label>
                            <div className="form-group">
                            <p>{user.nombre ? user.nombre : '' } {user.apellido ? user.apellido : ''}</p> 
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label htmlFor="fecha_salida">Fecha Salida</label>
                                <Field type="date" name="fecha_salida" placeholder="dd/mm/yy" className={`form-control ${touched.fecha_salida && errors.fecha_salida ? "is-invalid" : ""}`} />
                                <ErrorMessage component="div" name="fecha_salida" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label htmlFor="fecha_fin">Fecha Finalización</label>
                                <Field type="date" name="fecha_fin" placeholder="dd/mm/yy" className={`form-control ${touched.fecha_fin && errors.fecha_fin ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="fecha_fin" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label htmlFor="fecha_regreso">Fecha Regreso</label>
                                <Field type="date" name="fecha_regreso" placeholder="dd/mm/yy" className={`form-control ${touched.fecha_regreso && errors.fecha_regreso ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="fecha_regreso" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label htmlFor="dias">Días laborales</label>
                                <Field type="number" name="dias" className={`form-control ${touched.dias && errors.dias ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="dias" className="invalid-feedback" />
                                <label>Cantidad de días disponibles: {this.props.dias}</label>
                            </div>
                        </div>
                        
                        <div className="col-12 col-md-12">
                            <div className="row">

                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="tipo_id">Tipo</label>

                                        <MySelect
                                            value={values.tipo_id||''}
                                            onChange={(field, value)=>{
                                                const newCadena = value.value;
                                                const shoulReset = newCadena !== this.state.prevTipo;
                                                this.setState({ prevTipo: newCadena }, () => {
                                                    setFieldValue(field, value);
                                                    if (shoulReset && value.label === 'Vacaciones') {
                                                        this.setState({isVacaciones: true});
                                                        this.setState({isLicencia: true});
                                                    }else if(shoulReset && value.label === 'Licencia con goce de salario'){
                                                        this.setState({isVacaciones: false});
                                                        this.setState({isLicencia: false});
                                                    }else{
                                                        this.setState({isVacaciones: false});
                                                        this.setState({isLicencia: true});
                                                    }
                                                    if (shoulReset && value.label === 'Suspensión IGSS') {
                                                        this.setState({isIgss: true});
                                                    }else{
                                                        this.setState({isIgss: false});
                                                    }
                                                    setFieldValue("movimiento_id", emptyOption);
                                                    this.setState({tiendas: emptyOption});
                                                    var motivo = this.state.tipos.filter(function(e){ return e.value === newCadena;});
                                                    if(motivo[0] !== undefined){
                                                        this.setState({motivos: motivo[0].childs})
                                                    }else{
                                                        this.setState({motivos: []})
                                                    }
                                                    if (shoulReset) {
                                                        setFieldValue('movimiento_id',{});
                                                    }
                                                });
                                            }}
                                            onBlur={setFieldTouched}
                                            error={errors.tipo_id}
                                            touched={touched.tipo_id}
                                            field={'tipo_id'}
                                            options={this.state.tipos}
                                        />
                                        <ErrorMessage component="div" name="tipo_id" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className={!this.state.isVacaciones? 'col-12 col-md-6' : 'col-12 col-md-3 d-none'}>
                                    <div className="form-group">
                                        <label htmlFor="otros">Otros</label>
                                        <Field type="text" name="otros" value={values.otros || ''}  placeholder="Otro Motivo" className={`form-control ${touched.otros && errors.otros ? "is-invalid" : "" }`} />
                                        <ErrorMessage component="div" name="otros" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className={!this.state.isVacaciones && !this.state.isLicencia? 'col-12 col-md-6' : 'col-12 col-md-6 d-none'}>
                                    <div className="form-group">
                                        <label htmlFor="tipo_licencia_id">Opciones para licencias con goce de salario</label>

                                        <MySelect
                                            value={values.tipo_licencia_id||''}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            error={errors.tipo_licencia_id}
                                            touched={touched.tipo_licencia_id}
                                            field={'tipo_licencia_id'}
                                            options={this.state.licencias}
                                        />
                                        <ErrorMessage component="div" name="tipo_licencia_id" className="invalid-feedback" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="comentarios">Comentarios</label>
                                <Field type="text" name="comentarios" value={values.comentarios || ''} placeholder="Motivo" className={`form-control ${touched.comentarios && errors.comentarios ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="comentarios" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <label></label>
                            <div className="form-group">
                                <Field type="checkbox" id="saldra_pais" checked={values.saldra_pais == 1} name="saldra_pais" onChange={() => {setFieldValue("saldra_pais", !values.saldra_pais);}} value={1} className={`form-control no-style ${touched.saldra_pais && errors.saldra_pais ? "is-invalid" : "" }`} />  <label htmlFor="saldra_pais">Viajar fuera del pais</label>
                                <ErrorMessage component="div" name="saldra_pais" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="form-group">
                                <label htmlFor="lugar">Lugar</label>
                                <Field type="text" name="lugar" value={(values.lugar !== null) ? values.lugar : '' || ''}   className={`form-control ${touched.lugar && errors.lugar ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="lugar" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <label>Acepta que todos los datos proporcionados son correctos</label>
                            <div className="form-group">
                                <Field type="checkbox" id="acepta_empleado" checked={values.acepta_empleado == 1}  name="acepta_empleado" onChange={() => {setFieldValue("acepta_empleado", !values.acepta_empleado);}} value={1} className={`form-control no-style ${touched.acepta_empleado && errors.acepta_empleado ? "is-invalid" : "" }`} />  <label htmlFor="acepta_empleado">Acepto</label>
                                <ErrorMessage component="div" name="acepta_empleado" className="invalid-feedback" />
                            </div>
                        </div>
                        
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="archivo_firmado">Archivo por Firmar</label>
                                <input type="file" id="archivo_firmado" name="archivo_firmado" onChange={(event) => { setFieldValue("archivo_firmado", event.currentTarget.files[0]);}} className={`form-control ${touched.archivo_firmado && errors.archivo_firmado ? "is-invalid" : "" }`} />
                                {
                                    this.state.isIgss && this.state.archivo_vacaciones && this.state.archivo_vacaciones.archivo !== '' && this.state.archivo_igss && this.state.archivo_igss.archivo !== '' ?
                                    <a href={apiUrl+`storage/uploads/files/${this.state.archivo_igss.archivo}`} target="_blank" download="download" rel="noopener noreferrer">Ver archivo para firmar</a>
                                    :
                                    <a href={apiUrl+`storage/uploads/files/${this.state.archivo_vacaciones.archivo}`} target="_blank" download="download" rel="noopener noreferrer">Ver archivo para firmar</a>
                                }
                                <ErrorMessage component="div" name="archivo_firmado" className="invalid-feedback" />
                            </div>
                        </div>
                        {
                            !this.state.isLicencia &&
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label htmlFor="archivo_extra">Archivo Extra</label>
                                    <input type="file" id="archivo_extra" name="archivo_extra" onChange={(event) => { setFieldValue("archivo_extra", event.currentTarget.files[0]);}} className={`form-control ${touched.archivo_extra && errors.archivo_extra ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="archivo_extra" className="invalid-feedback" />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert, vacaciones } = state;
    return {
        alert,
        motivos: vacaciones.motivos,
        tipos: vacaciones.tipos,
        licencias: vacaciones.licencias,
        dias: vacaciones.dias
    };
}


export default connect(mapStateToProps)(VacacionesForm);